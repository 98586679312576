import React from "react";

interface iInvoicingPoundIcon {
  className?: string;
}

const InvoicingPoundIcon = ({ className }: iInvoicingPoundIcon) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={className} stroke="currentColor">
    <path d="M7.50024 6.24976H12.5002" strokeWidth="1.51018" strokeLinecap="round"/>
    <path d="M7.50024 9.24951H12.5002" strokeWidth="1.51018" strokeLinecap="round"/>
    <path d="M15.5 6.24976H16.5" strokeWidth="1.51018" strokeLinecap="round"/>
    <path d="M15.5 9.24951H16.5" strokeWidth="1.51018" strokeLinecap="round"/>
    <path d="M4.5 20.7487V3.24993C4.5 2.8895 5 2.25001 5.5 2.25L18.5 2.25001C19 2.25001 19.5 2.74997 19.5 3.24993V20.7487C19.5 21.301 19.0523 21.7487 18.5 21.7487C18 21.7487 6 21.7517 5.5 21.7487C5 21.7456 4.5 21.2487 4.5 20.7487Z" strokeWidth="1.51018" strokeLinecap="round"/>
    <path d="M13.6667 13.1736C12.6752 12.1821 11.3531 12.8431 11.0226 13.1735C10.6921 13.504 10.3616 13.9183 10.3616 14.4955C10.3616 15.1565 10.6279 15.493 10.6921 16.1479C10.7551 16.7902 10.8525 17.1626 10.7661 17.8021C10.6948 18.3295 10.1712 19.3224 10.4356 19.1241C11.0919 18.6319 11.7191 18.4614 12.5277 18.7919C13.3362 19.1223 13.9024 18.9624 14.4017 18.4631" strokeWidth="0.946614" strokeLinecap="round"/>
    <path d="M9.7002 16.1484H10.6917H12.3443" strokeWidth="0.946614" strokeLinecap="round"/>
  </svg>

);

export default InvoicingPoundIcon;
